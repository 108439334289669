//import axios from "axios"
import PublicGoogleSheetsParser from "public-google-sheets-parser"

const spreadsheetId = "1AwaZMWa4wwE5W2AS7ioNjl3eQKckyEN0GRFKsjIVrgw"
const shows = [
    { displayName: "2011", showId: "2011" },
    { displayName: "1/2012", showId: "2012a" },
    { displayName: "2/2012", showId: "2012b" },
    { displayName: "2013", showId: "2013" },
    { displayName: "2014", showId: "2014" },
    { displayName: "2015", showId: "2015" },
    { displayName: "2016", showId: "2016" },
    { displayName: "2019", showId: "2019" },
    { displayName: "2020", showId: "2020" },
    { displayName: "2021", showId: "2021" }
]

export const PlaylistAPI = {
    getShows: () => shows,
    getPlaylist: async (showId) => {
        const options = { sheetName: showId }
        const parser = new PublicGoogleSheetsParser(spreadsheetId, options)
        return parser.parse()
    },
    getDisplayName: (showId) => {
        return shows.find((show) => show.showId === showId)?.displayName ?? null
    }
}

export default PlaylistAPI
