import React, { useState } from "react"

export const HoverImage = ({ src, hoverSrc, ...rest }) => {
    const [image, setImage] = useState(src)

    return (
        <img
            src={image}
            onMouseOver={() => {
                setImage(hoverSrc)
            }}
            onMouseOut={() => {
                setImage(src)
            }}
            {...rest}
        />
    )
}

export default HoverImage
