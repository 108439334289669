import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import PlaylistAPI from "../PlaylistApi"

import PlaylistItem from "./PlaylistItem"

export const Playlist = () => {
    const { showId } = useParams()
    const [playlist, setPlaylist] = useState([])
    const [playlistName, setPlaylistName] = useState("")
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchPlaylist = async () => {
            setIsLoading(true)
            setIsError(false)

            const displayName = PlaylistAPI.getDisplayName(showId)

            if (!displayName) {
                setIsError(true)
            } else {
                await PlaylistAPI.getPlaylist(showId)
                    .then(setPlaylist)
                    .catch(() => {
                        setIsError(true)
                    })
                setPlaylistName(displayName)
            }
            setIsLoading(false)
        }

        fetchPlaylist()
    }, [showId])

    return (
        <div className="playlist">
            {isError ? (
                <Link className="not-found" to="/">
                    SNAFU
                </Link>
            ) : (
                <>
                    {isLoading ? (
                        <h1 className="playlist-title">...</h1>
                    ) : (
                        <>
                            <h1 className="playlist-title">{playlistName}</h1>
                            {playlist.map((song, index) => (
                                <PlaylistItem song={song} key={index} />
                            ))}
                            = {playlist.length}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Playlist
