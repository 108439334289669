import React, { useEffect, useRef, useState } from "react"

export const Home = () => {
    const message = "INTERNETMÄDÄTTÄÄAIVOT"
    const [char, setChar] = useState("")
    const index = useRef(0)

    const displayTime = 20
    const hideTime = 1000
    const pauseTime = 20000

    useEffect(() => {
        const startLoop = () =>
            setTimeout(() => {
                setChar(message[index.current])
                hideText()
            }, pauseTime)

        const updateCharacter = () =>
            setTimeout(() => {
                if (index.current == message.length) {
                    index.current = 0
                    startLoop()
                } else {
                    index.current = index.current + 1
                    setChar(message[index.current])
                    hideText()
                }
            }, hideTime)

        const hideText = () =>
            setTimeout(() => {
                setChar("")
                updateCharacter()
            }, displayTime)

        startLoop()
    }, [])

    return <div className="overlay">{char}</div>
}

export default Home
