import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import "./mordor.css"

import Home from "./components/Home"
import Playlist from "./components/Playlist"
import Layout from "./components/Layout"

export const Mordor = () => {
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/:showId" element={<Playlist />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Mordor
