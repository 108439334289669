import React, { useState, useEffect } from "react"
import zalgo from "zalgo-js"
import { Link, Outlet, useParams } from "react-router-dom"
import PlaylistAPI from "../PlaylistApi"

export const Layout = () => {
    const { showId } = useParams()
    const [shows, setShows] = useState([])
    const zalgoOptions = {
        directions: {
            up: true,
            middle: true,
            down: false
        },
        intensity: 0.4
    }

    useEffect(() => {
        setShows(PlaylistAPI.getShows())
    }, [showId])

    const links = shows.map((show) => {
        return showId !== show.showId ? (
            <Link key={show.showId} to={show.showId} className="link">
                {show.displayName}
            </Link>
        ) : (
            <Link key={show.showId} to="/" className="link selected">
                {zalgo(show.displayName, zalgoOptions)}
            </Link>
        )
    })

    return (
        <>
            <Outlet />
            <div className="links">{links}</div>
        </>
    )
}

export default Layout
