import React from "react"

import HoverImage from "./HoverImage"

import youtubeUnhovered from "../icons/yt_icon_mono_dark.png"
import youtubeHovered from "../icons/yt_icon_rgb.png"
import facebookUnhovered from "../icons/f_logo_RGB-White_58.png"
import facebookHovered from "../icons/f_logo_RGB-Blue_58.png"
import spotifyUnhovered from "../icons/Spotify_Icon_RGB_White.png"
import spotifyHovered from "../icons/Spotify_Icon_RGB_Green.png"
import bandcampUnhovered from "../icons/bandcamp-button-circle-line-white-64.png"
import bandcampHovered from "../icons/bandcamp-button-circle-line-aqua-64.png"
import soundcloudUnhovered from "../icons/white_transparent_64-94fc761.png"
import soundcloudHovered from "../icons/orange_transparent_64-94fc761.png"

const youtubeIcon = () => (
    <HoverImage
        src={youtubeUnhovered}
        hoverSrc={youtubeHovered}
        className="icon"
        alt="YouTube"
    />
)

const spotifyIcon = () => (
    <HoverImage
        src={spotifyUnhovered}
        hoverSrc={spotifyHovered}
        className="icon"
        alt="Spotify"
    />
)

const facebookIcon = () => (
    <HoverImage
        src={facebookUnhovered}
        hoverSrc={facebookHovered}
        className="icon"
        alt="Facebook"
    />
)

const bandcampIcon = () => (
    <HoverImage
        src={bandcampUnhovered}
        hoverSrc={bandcampHovered}
        className="icon"
        alt="Bandcamp"
    />
)

const soundcloudIcon = () => (
    <HoverImage
        src={soundcloudUnhovered}
        hoverSrc={soundcloudHovered}
        className="icon"
        alt="SoundCloud"
    />
)

export const PlaylistItem = (props) => {
    const artist = props.song.artist,
        title = props.song.title,
        youtube = props.song.youtube,
        spotify = props.song.spotify,
        facebook = props.song.facebook,
        bandcamp = props.song.bandcamp,
        soundcloud = props.song.soundcloud

    return (
        <li>
            {artist} - {title}
            {youtube ? <a href={youtube}>{youtubeIcon()}</a> : ""}
            {spotify ? <a href={spotify}>{spotifyIcon()}</a> : ""}
            {facebook ? <a href={facebook}>{facebookIcon()}</a> : ""}
            {bandcamp ? <a href={bandcamp}>{bandcampIcon()}</a> : ""}
            {soundcloud ? <a href={soundcloud}>{soundcloudIcon()}</a> : ""}
        </li>
    )
}

export default PlaylistItem
